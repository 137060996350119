<template>
    <div class="text-center m-auto no-project">
        <img alt="No project Found" class="error-img" src="../../../assets/images/no-project-found-new.svg">
        <p class="error_text">No project found</p>
    </div>
</template>

<script>
    export default {
        name : "no-project-found"
    }
</script>

<style scoped>
    .error_text {
        font-size   : larger;
        font-weight : 500;
        color       : #A9BCDB;
        margin-top  : 20px;
    }

    .error-img {
        height      : 250px;
    }

    @media (max-width : 320px) {
        .no-project {
            width     : 65% !important;
        }
    }

    @media (min-width : 375px) and (max-width : 424px) {
        .no-project {
            width       : 65% !important;
            margin-left : 35px !important;
            margin-top  : 20px !important;
        }
    }

    @media (min-width : 425px) and (max-width : 767px) {
        .no-project {
            width       : 65% !important;
            margin-left : 35px !important;
            margin-top  : 20px !important;
        }
    }

    @media (min-width : 768px) and (max-width : 1023px) {
        .no-project {
            margin-top : 50px !important;
        }
    }

    @media (min-width : 1024px) and (max-width : 1439px) {
        .no-project {
            margin-top : 70px !important;
        }
    }

    @media (min-width : 1440px) and (max-width : 2559px) {
        .no-project {
            margin-top : 70px !important;
        }
    }

    @media (min-width : 2560px) {
        .no-project {
            margin-top : 70px !important;
        }
    }
</style>
